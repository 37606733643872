<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="name"
                  return-object
                  :items="customers"
                  v-model="customer"
                  @change="onChangeCustomer"
                  :rules="[v => !!v || 'Customer is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>No Form # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="lastFormNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.returnNumber"
                  :rules="[v => !!v || 'Return number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuReturnDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.returnDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.returnDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuReturnDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Down Payment</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.downPayment" @change="onChangeDownPayment"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return From <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="invoiceNumber"
                  return-object
                  :items="listInvoice"
                  v-model="invoice"
                  @change="onChangeInvoice"
                  :rules="[v => !!v || 'Invoice is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  v-model="form.remark"
                  :rules="[v => !!v || 'Remark is required']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">PPN</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-product
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-sale-product>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogSaleProduct from "@/components/DialogSaleProduct";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-sale-return",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-sale-product": DialogSaleProduct,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      returnNumber: "",
      downPayment: false,
      percentage: 0,
      percentagePpn: 0,
      returnDate: moment().format("yyyy-MM-DD"),
      customerId: null,
      customerName: null,
      customerAddress: null,
      saleInvoiceId: null,
      totalAmount: 0,
      taxAmount: 0,
      products: [],
      status: "Outstanding",
      remark: "",
    },
    menuReturnDate: false,
    form: {},
    items: [],
    customer: null,
    invoice: null,
    listInvoice: [],
    accounts: [],
    dialog: false,
    item: {},
    index: -1,
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Mat Doc#",
        value: "materialDocumentNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.total * (this.form.percentage / 100);
      });
      return subtotal;
    },
    taxAmount() {
      return this.totalAmount * (this.form.percentagePpn / 100);
    },
    ...mapState("external", ["customers"]),
    ...mapState("saleReturn", ["lastFormNumber"]),
  },

  watch: {
    totalAmount(val) {
      this.form.totalAmount = val;
    },
    taxAmount(val) {
      this.form.taxAmount = val;
    },
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.products = this.items;

        let totalDebit = 0;
        let totalCredit = 0;

        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          await this.$store
            .dispatch("saleReturn/create", this.form)
            .then(() => {
              this.clear();
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Dredit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    isJournalBalance() {
      let totalDebit = 0;
      let totalCredit = 0;
      return totalDebit - totalCredit === 0;
    },
    onChangeCustomer(val) {
      this.form.customerId = val.id;
      this.form.customerName = val.name;
      this.form.customerAddress = val.address.street;
      if (this.form.downPayment) {
        this.getListInvoiceDP();
      } else {
        this.getListInvoice();
      }
    },
    onChangeDownPayment(val) {
      if (val) {
        this.getListInvoiceDP();
      } else {
        this.getListInvoice();
      }
    },
    async getListInvoiceDP() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleReturn/getListInvoiceDP", this.form.customerId)
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getListInvoice() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleReturn/getListInvoice", this.form.customerId)
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeInvoice(val) {
      this.form.saleInvoiceId = val.id;
      this.form.percentage = val.percentage;
      this.form.percentagePpn = val.percentagePpn;
      this.items = val.products;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addItem(item) {
      this.items[this.index] = item;
    },
    close() {
      this.dialog = false;
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    clear() {
      this.customer = null;
      this.invoice = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("saleReturn/getLastFormNumber"),
        await this.$store.dispatch("external/getCustomers"),
        this.getAccounts(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
