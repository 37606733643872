<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Product Detail</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="item.partDesc"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Unit Measure</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <span>{{ item.unitMeasure }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Quantity</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="3" lg="3" class="py-0">
                <v-text-field
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model.number="item.quantity"
                  @change="onInputQuantity"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Unit Price</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(item.unitPrice) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.discount }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(item.total) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>PO#</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.poNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="item.doNumber !== ''">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>DO#</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.doNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Line Item</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.lineItem }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Material Document #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model="item.materialDocumentNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="item.accountNumber !== '611.00000'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH 23 (%)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="3" lg="3" class="py-0">
                <v-text-field
                  flat
                  dense
                  outlined
                  background-color="white"
                  v-model.number="item.pph23"
                  suffix="%"
                  @change="onInputPph23"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="item.accountNumber !== '611.00000'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH 23 Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <span>{{ formatPrice(item.pph23Amount) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountName"
                  return-object
                  v-model="account"
                  :items="accounts"
                  @change="onChangeAccount"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="item.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-sale-product",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    accounts: Array,
    percentage: Number,
  },

  data() {
    return {
      account: {},
    };
  },

  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
    save() {
      this.$emit("save", { index: this.index, item: this.item });
      this.$emit("close");
      this.account = null;
    },
    close() {
      this.$emit("close");
      this.account = null;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeAccount(val) {
      this.item.accountNumber = val.accountNumber;
      this.item.accountName = val.accountName;
    },
    onInputDiscount(val) {
      if (val > 100) {
        this.item.discountAmount = val;
        this.item.total = this.item.total - this.item.discountAmount;
      } else {
        this.item.discountAmount = (val / 100) * this.item.total;
        this.item.total = this.item.total - this.item.discountAmount;
      }
    },
    onInputQuantity(val) {
      if (this.item.discount > 100) {
        this.item.discountAmount = this.item.discount;
        this.item.total = this.item.unitPrice * val - this.item.discountAmount;
      } else {
        this.item.discountAmount = (this.item.discount / 100) * this.item.total;
        this.item.total = this.item.unitPrice * val - this.item.discountAmount;
      }
    },
    onInputPph23() {
      let total = (this.item.total * this.percentage) / 100;
      this.item.pph23Amount = (total * this.item.pph23) / 100;
    },
  },
};
</script>

<style></style>
